<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent hide-orverlay="true" max-width="730">
      <v-card>
        <v-card-title class="headline">
          <h1>Nova Meta</h1>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout pa-4 row wrap>
            <v-flex xs12 my0>
              <InputText
                ref="goalNameInput"
                v-model="goal.name"
                type="text"
                place-holder="Dê um nome para esta meta"
                textLabel="Nome da meta"
                helpLabel="Ex: Aumento de Arrecadação"
                invalidInputMessage="Preencha o nome da meta"
              />
            </v-flex>
            <v-flex
              my0
              :class="goal.goalVerificationId == goalVerification.Outros ? 'xs6' : 'xs12'"
            >
              <InputSelect
                ref="goalVerificationIdInput"
                v-model="goal.goalVerificationId"
                :items="groupVerification"
                valueAttribute="id"
                textLabel="Comprovação de meta"
                textAttribute="name"
                helpLabel="Ex: auditoria"
                invalidInputMessage="Selecione o tipo de comprovação da meta"
              ></InputSelect>
            </v-flex>
            <v-flex my0 v-if="goal.goalVerificationId == goalVerification.Outros" xs6 pl-1>
              <InputText
                ref="goalOthersVerificationInput"
                v-model="goal.othersVerification"
                type="text"
                place-holder="Descreva em detalhes"
                textLabel="Outros"
                invalidInputMessage="Descreva como será feita a comprovação"
              />
            </v-flex>
            <v-flex xs6 pr-1 my0>
              <InputSelect
                ref="goalTypeIdInput"
                v-model="goal.goalTypeId"
                :items="groupType"
                valueAttribute="id"
                textAttribute="name"
                textLabel="Tipo de meta"
                helpLabel="Ex: Valor"
                invalidInputMessage="Selecione o tipo da meta"
              ></InputSelect>
            </v-flex>
            <v-flex xs6 pl-1 my0>
              <InputMoney
                ref="goalAmountInput"
                v-model="goal.amount"
                place-holder="Valor"
                textLabel="Informe o montante"
                helpLabel="Ex: 1000"
                :precision="0"
                prefix
                @input="createSuggestions"
                invalidInputMessage="Preencha o montante da meta"
              />
            </v-flex>
            <v-flex xs12>
              <div
                class="title-goal-month"
              >Separe {{ monthsFrequency[project.goalFrequency] }} a sua meta:</div>
            </v-flex>
            <template v-for="(goalValue, index) in goal.goalsValuesList">
              <v-flex xs3 pr-2 :key="index">
                <InputMoney
                  ref="goalValueInput"
                  v-model="goalValue.value"
                  place-holder="250"
                  :textLabel="index+1 + 'º ' + months[project.goalFrequency]"
                  invalidInputMessage="Preencha o valor"
                  :precision="0"
                  :required="false"
                  prefix
                />
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn
              flat
              round
              color="white"
              class="btn btn-default px-5"
              large
              @click="close"
            >Cancelar</v-btn>
            <v-btn flat round color="white" class="btn btn-primary px-5" large @click="save">Salvar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import Goal from "@/scripts/models/goal.model";
import GoalsValues from "@/scripts/models/goalsValues.model";
import { GoalVerification } from "@/scripts/models/enums/goalVerification.enum";
import { constants } from "crypto";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import ProjectService from "@/scripts/services/project.service";
export default {
  extends: ValidatableComponent,
  props: ["project", "groupType", "groupVerification", "numberOfFields"],
  data() {
    return {
      dialog: false,
      goal: new Goal(this.numberOfFields),
      projectService: new ProjectService(),
      originalGoal: null,
      goalVerification: GoalVerification,
      monthsFrequency: {
        1: "mensalmente",
        2: "bimestralmente",
        3: "trimestralmente"
      },
      months: { 1: "mês", 2: "bimestre", 3: "trimestre" }
    };
  },
  created() {
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
  },
  methods: {
    listByProjectId() {
      this.projectService.listGoalByProjectId(this.project.id).then(
        function(data) {
          this.$emit("input", data);
        }.bind(this)
      );
    },
    save() {
      if (this.isValid()) {
        this.originalGoal.projectId = this.project.id;
        this.projectService.saveGoal(this.refreshList, this.originalGoal);
      }
    },
    remove(goalId) {
      this.projectService.deleteGoal(this.refreshList, goalId);
    },
    refreshList() {
      this.listByProjectId();
      this.close();
      this.$emit("save");
    },
    validate(performMethods) {
      if (this.dialog == false) return true;
      return this.validateComponents(performMethods);
    },
    validationSuccess() {
      if (this.dialog == false) return;
      this.copy(this.goal, this.originalGoal);
    },
    open(item) {
      this.dialog = true;
      this.originalGoal = item;
      this.copy(this.originalGoal, this.goal);
      this.removeCanceledValuesIfExists();
      this.$refs.goalNameInput.valid = true;
      this.$refs.goalVerificationIdInput.valid = true;
      if (this.$refs.goalOthersVerificationInput)
        this.$refs.goalOthersVerificationInput.valid = true;
      this.$refs.goalTypeIdInput.valid = true;
      this.$refs.goalAmountInput.valid = true;
      if (this.$refs.goalValueInput) {
        for (let i = 0; i < this.$refs.goalValueInput.length; i++)
          this.$refs.goalValueInput[i].valid = true;
      }
    },
    close() {
      this.dialog = false;
      this.copy(new Goal(this.numberOfFields), this.goal);
    },
    copy(from, to) {
      to.Order = from.Order;
      to.id = from.id;
      to.projectId = from.projectId;
      to.name = from.name;
      to.goalTypeId = from.goalTypeId;
      to.amount = from.amount;
      to.goalVerificationId = from.goalVerificationId;
      to.othersVerification = from.othersVerification;
      to.frequency = from.frequency;
      to.goalsValuesList = [];
      for (let i = 0; i < from.goalsValuesList.length; i++) {
        let goalValue = new GoalsValues();
        this.copyGoalValue(from.goalsValuesList[i], goalValue);
        to.goalsValuesList.push(goalValue);
      }
    },
    copyGoalValue(from, to) {
      to.order = from.order;
      to.id = from.id;
      to.goalId = from.goalId;
      to.value = from.value;
      to.statusId = from.statusId;
      to.initialDate = from.initialDate;
      to.finalDate = from.finalDate;
      to.realized = from.realized;
      to.canceled = from.canceled;
      to.title = from.title;
      to.description = from.description;
      to.goalsValuesFiles = [];
      if (from.goalsValuesFiles) {
        for (let i = 0; i < from.goalsValuesFiles.length; i++) {
          let goalValueFile = {
            id: from.goalsValuesFiles[i].id,
            fileId: from.goalsValuesFiles[i].fileId,
            goalsValuesId: from.goalsValuesFiles[i].goalsValuesId
          };
          to.goalsValuesFiles.push(goalValueFile);
        }
      }
    },
    removeCanceledValuesIfExists() {
      this.goal.goalsValuesList = this.goal.goalsValuesList.filter(
        x => !x.canceled
      );
    },
    createSuggestions() {
      if (this.goal.amount != this.originalGoal.amount) {
        let currentAmount = Number(this.goal.amount);
        if (this.project.sameBeneficiaries) {
          for (let i = 0; i < this.numberOfFields; i++) {
            this.goal.goalsValuesList[i].order = i;
            this.goal.goalsValuesList[i].value = currentAmount;
          }
        } else {
          if (!Number.isNaN(currentAmount)) {
            let arrValues = this.splitValue(currentAmount, this.numberOfFields);
            for (let i = 0; i < arrValues.length; i++) {
              this.goal.goalsValuesList[i].order = i;
              this.goal.goalsValuesList[i].value = arrValues[i];
            }
          }
        }
      }
    },
    splitValue(value, parts) {
      var result = [];
      let remain = value;
      let partsLeft = parts;
      for (var i = 0; partsLeft > 0; i++) {
        let current = Math.floor((remain + partsLeft - 1) / partsLeft);
        result[i] = current;
        remain -= current;
        partsLeft--;
      }
      return result;
    }
  }
};
</script>